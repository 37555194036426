const APIRequest = (params) =>
{
    return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            fetch(params.url, {
                method: params.method,
                headers: headers,
                body: JSON.stringify(params.body)
            }).then(response => {
                if (response.status === 401)
                {
                    reject({reason: "Unauthorized", details: response});
                }
                if (!response.ok)
                    throw Error(response)
                return response.json();
            }).then(data => {
                resolve(data);
            }).catch(error => {
                reject({reason: "Unknown", details: error})
            })
        });
    }

export default APIRequest;
