import React from 'react';

import APIRequest from './API/APIRequest';
import config from './Config.js';
import './custom.css'
import { Button } from 'react-bootstrap';

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "",
    }
  }

  static displayName = App.name;

  componentDidMount() {
      this.test()
  }


  test = () => {
      APIRequest({
          url: config.baseURL + "/system/status",
          method: "get"
      });
  }

  render() {
    return (
      <div className="page">
            <div className="app-container">
               <h1>Valet 2</h1>
        </div>
      </div>
    );
  }
}
